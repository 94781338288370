// src/components/ModelList.jsx
import React, { useState } from 'react';
import ModelItem from './ModelItem';

const ModelList = ({ models, sasInfo, onModelClick }) => {
  const [selectedModel, setSelectedModel] = useState(null);

  const handleModelClick = (model) => {
    setSelectedModel(model);
    onModelClick(model);
  };

  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {models.map((model, index) => (
        <ModelItem
          key={index}
          model={model}
          isSelected={selectedModel === model}
          onClick={handleModelClick}
          sasInfo={sasInfo}
        />
      ))}
    </ul>
  );
};

export default ModelList;