// src/components/MediaRightContentTrainingSetList.jsx
import React, { useEffect, useState } from 'react';
import TrainingSetList from './TrainingSetList';
import { getTrainingSetPhotos, deleteTrainingSetPhoto } from '../utils/mediaUtils';
import { useSasInfo } from '../hooks/useSasInfo';

const MediaRightContentTrainingSetList = ({ selectedModel, onSelect }) => {
  const [photos, setPhotos] = useState([]);
  const { sasInfo } = useSasInfo();

  useEffect(() => {
    const fetchPhotos = async () => {
      if (selectedModel && sasInfo) {
        try {
          const photosList = await getTrainingSetPhotos(sasInfo, selectedModel);
          setPhotos(photosList);
        } catch (error) {
          console.error('Error fetching photos:', error);
        }
      }
    };

    fetchPhotos();
  }, [selectedModel, sasInfo]);

  const handleDelete = async (photo) => {
    if (selectedModel && sasInfo) {
      try {
        await deleteTrainingSetPhoto(sasInfo, selectedModel, photo);
        setPhotos(photos.filter(p => p !== photo));
      } catch (error) {
        console.error('Error deleting photo:', error);
      }
    }
  };

  return (
    <div>
      <TrainingSetList photos={photos} onSelect={onSelect} onDelete={handleDelete} />
    </div>
  );
};

export default MediaRightContentTrainingSetList;