// src/components/MediaContentLeftModelList.jsx
import React, { useEffect, useState } from 'react';
import ModelList from './ModelList';
import { getModels } from '../utils/mediaUtils';
import { useSasInfo } from '../hooks/useSasInfo';

const MediaContentLeftModelList = ({ onModelSelect }) => {
  const [models, setModels] = useState([]);

  // add logging to see when the component is rendered
  console.log('MediaContentLeftModelList rendered');
  const { sasInfo, isLoading, error } = useSasInfo();

  useEffect(() => {
    const fetchModels = async () => {
      if (sasInfo) {
        try {
          const modelsList = await getModels(sasInfo);
          setModels(modelsList);
        } catch (error) {
          console.error('Error fetching models:', error);
          setModels([]);
        }
      }
    };

    fetchModels();
  }, [sasInfo]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading models</p>;
  }

  return (
    <div>
      {models.length > 0 ? (
        <ModelList models={models} sasInfo={sasInfo} onModelClick={onModelSelect} />
      ) : (
        <p>No model available</p>
      )}
    </div>
  );
};

export default MediaContentLeftModelList;
