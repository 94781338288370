// src/components/MediaTop.js
import React, { useEffect } from 'react';

const MediaTop = () => {

  return (
    <div style={{ backgroundColor: '#f8d7da', padding: '20px' }}>
      <h1>Media Page</h1>
    </div>
  );
};

export default MediaTop;
