import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";


export const Home = () => {
    
    const { instance } = useMsal();    
    const activeAccount = instance.getActiveAccount();

    return (
        <>
            <AuthenticatedTemplate>
                {
                    activeAccount ?
                    <Container>
                    </Container>
                    :
                    null
                }
            </AuthenticatedTemplate>
        </>
    )
}