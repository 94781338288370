// src/components/MediaContentLeft.jsx
import React from 'react';
import MediaContentLeftModelList from './MediaContentLeftModelList';
import MediaContentLeftNewModel from './MediaContentLeftNewModel';

const MediaContentLeft = ({ onModelSelect }) => {
  return (
    <div style={{ flex: '0 0 30%', backgroundColor: '#f0f8ff', padding: '20px' }}>
      <MediaContentLeftNewModel />
      <MediaContentLeftModelList onModelSelect={onModelSelect} />
    </div>
  );
};

export default MediaContentLeft;
