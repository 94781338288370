// src/components/MediaRightContentTrainingSetListPreview.jsx
import React, { useEffect, useState } from 'react';
import { getTrainingSetPhotoContent } from '../utils/mediaUtils';
import { useSasInfo } from '../hooks/useSasInfo';

const MediaRightContentTrainingSetListPreview = ({ selectedModel, selectedPhoto }) => {
  const [photoUrl, setPhotoUrl] = useState(null);
  const { sasInfo } = useSasInfo();

  useEffect(() => {
    console.log('Selected model:', selectedModel);
    selectedPhoto = null;
  }, [selectedModel]);

  useEffect(() => {
    const fetchPhotoContent = async () => {
      if (selectedModel && selectedPhoto && sasInfo) {
        const cacheKey = `photo-${selectedPhoto}`;
        const cachedPhotoUrl = localStorage.getItem(cacheKey);

        if (cachedPhotoUrl) {
          // Check if the cached photo URL is valid
          const img = new Image();
          img.onload = () => setPhotoUrl(cachedPhotoUrl);
          img.onerror = async () => {
            // If the cached photo URL is invalid, remove it from localStorage and refetch the photo
            localStorage.removeItem(cacheKey);
            try {
              const url = await getTrainingSetPhotoContent(sasInfo, selectedModel, selectedPhoto);
              setPhotoUrl(url);
              localStorage.setItem(cacheKey, url);
            } catch (error) {
              console.error('Error fetching photo content:', error);
            }
          };
          img.src = cachedPhotoUrl;
        } else {
          try {
            const url = await getTrainingSetPhotoContent(sasInfo, selectedModel, selectedPhoto);
            setPhotoUrl(url);
            localStorage.setItem(cacheKey, url);
          } catch (error) {
            console.error('Error fetching photo content:', error);
          }
        }
      }
    };

    fetchPhotoContent();
  }, [selectedModel, selectedPhoto, sasInfo]);

  useEffect(() => {
    // Reset photoUrl when selectedModel changes
    setPhotoUrl(null);
  }, [selectedModel]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%' }}>
      {photoUrl ? (
        <img src={photoUrl} alt="Selected" style={{ width: '30vw', height: '30vh', objectFit: 'contain' }} />
      ) : (
        <p>No photo selected</p>
      )}
    </div>
  );
};

export default MediaRightContentTrainingSetListPreview;