import { useState, useEffect } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getSasInfo } from "../utils/mediaUtils"; // Adjust the import path
import { protectedResources } from "../authConfig";

/**
 * Custom hook to retrieve SAS info using MSAL authentication
 */
export const useSasInfo = () => {

    console.log("useSasInfo called");
    const { instance } = useMsal();
    const { result, error: msalError } = useMsalAuthentication(InteractionType.Popup, {
        scopes: protectedResources.apiMedia.scopes.read,
        account: instance.getActiveAccount(),
        redirectUri: '/redirect'
    });

    const [isLoading, setIsLoading] = useState(true);
    const [sasInfo, setSasInfo] = useState(null);
    const [error, setError] = useState(msalError);

    useEffect(() => {
        const fetchSasInfo = async () => {
            console.log("fetchSasInfo called");
            if (error) {
                console.log("Error detected:", error);
                setIsLoading(false);
                return;
            }

            if (result?.accessToken) {
                console.log("Access token available, fetching SAS info...");
                try {
                    const sasData = await getSasInfo(result.accessToken);
                    console.log("SAS info fetched successfully:", sasData);
                    setSasInfo(sasData);
                } catch (e) {
                    console.error("Error fetching SAS info:", e);
                    setError(e);
                } finally {
                    setIsLoading(false);
                }
            } else {
                console.log("No access token available");
            }
        };

        // Trigger the function when result is available
        if (result) {
            console.log("Result available, calling fetchSasInfo...");
            fetchSasInfo();
        } else if (!error) {
            console.log("Result not available yet, still loading...");
            setIsLoading(true); // Still loading if result isn't available yet and no error has occurred
        } else {
            console.log("Error detected, not calling fetchSasInfo");
        }
    }, [result, error]);

    return { sasInfo, isLoading, error };
};
