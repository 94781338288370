// src/components/MediaBottom.js
import React from 'react';

const MediaBottom = () => {
  return (
    <div style={{ backgroundColor: '#d4edda', padding: '20px' }}>
      <p>Footer content goes here.</p>
    </div>
  );
};

export default MediaBottom;
