import { protectedResources } from "../authConfig";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from 'uuid';

/**
 * Function to get SAS info by calling a protected API with the provided access token
 * @param {string} accessToken - The access token to use for authentication
 * @returns {Promise<Object>} - The API response
 */
export const getSasInfo = async (accessToken) => {
    try {
        // Step 1: Set up headers with the provided access token
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        // Step 2: Make the API request
        const response = await fetch(protectedResources.apiMedia.endpoint, {
            method: "GET",
            headers: headers,
        });

        // Check if response is okay and return JSON response
        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        console.log("SAS Info:", data);
        // the object returned from the API should have the following structure:
        // {
        //     account_name: "<account_name>",
        //     container_name: "<container_name>",
        //     acount_url: "<account_url>",
        //     container_url: "<container_url>",
        //     sas_token: "<sas_token
        // }
        return data;

    } catch (error) {
        console.error("Error fetching SAS info:", error);
        throw error;
    }
};


export const listBlobs = async (containerInfo) => {
    try {
        console.log("Container info:", containerInfo);
        const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
        console.log("Full URL for BlobServiceClient:", fullUrl);

        // Initialize BlobServiceClient and ContainerClient
        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);
        console.log("ContainerClient initialized for container:", containerInfo.container_name);

        // Check if containerClient initialized correctly
        if (!containerClient) {
            throw new Error("Failed to initialize ContainerClient.");
        }

        // Fetch and list blobs
        const blobNames = [];
        for await (const blob of containerClient.listBlobsFlat()) {
            console.log("Found blob:", blob.name);
            blobNames.push(blob.name);
        }
        console.log("Total blobs found:", blobNames.length);
        return blobNames;
    } catch (error) {
        console.error("Error listing container contents:", error.message);
        throw error;
    }
};

export const createFolder = async (containerInfo, folderName) => {
    try {
        console.log("Creating folder:", folderName);
        console.log("Container info:", containerInfo);
        const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);

        // Append a dummy file name to the folder path
        const blockBlobClient = containerClient.getBlockBlobClient(`${folderName}/placeholder.txt`);
        
        // Use TextEncoder to encode the content for the dummy file
        const encoder = new TextEncoder();
        const content = encoder.encode("placeholder content");
        
        // Upload the dummy file to represent the folder
        await blockBlobClient.upload(content, content.length);
 
        console.log("Folder created successfully with placeholder file:", folderName);
    } catch (error) {
        console.error("Error creating folder:", error.message);
        throw error;
    }
};

// New function getModels
export const getModels = async (containerInfo) => {
    try {
        const blobNames = await listBlobs(containerInfo);
        console.log("Blob names:", blobNames);

        // Check if "Models" folder exists
        const modelsFolderExists = blobNames.some(name => name.startsWith("Models/"));
        
        if (!modelsFolderExists) {
             console.log("Models folder does not exist. Creating 'Models' folder...");
            await createFolder(containerInfo, "Models/");
        }

        // Filter and extract model names, ignoring subfolders and placeholder.txt
        const modelNames = Array.from(
            new Set(
            blobNames
                .filter(name => name.startsWith("Models/") && name.split("/").length > 1 && !name.endsWith("placeholder.txt"))
                .map(name => name.split("/")[1])
            )
        );

        return modelNames;
    } catch (error) {
        console.error("Error in getModels function:", error.message);
        throw error;
    }
};

export const newModel = async (containerInfo, modelName) => {
    try {
        const folderPath = `Models/${modelName}`;
        await createFolder(containerInfo, folderPath);
        console.log(`Model folder created successfully: ${folderPath}`);
        // Create a "TrainingSet" folder inside the new model folder
        await createFolder(containerInfo, `${folderPath}/TrainingSet`);
        console.log(`TrainingSet folder created successfully inside model folder: ${folderPath}`);
    } catch (error) {
        console.error("Error creating model folder:", error.message);
        throw error;
    }
};

export const deleteModel = async (containerInfo, modelName) => {
    try {
        console.log("Deleting model:", modelName);
        console.log("Container info:", containerInfo);

        const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
        console.log("Full URL for BlobServiceClient:", fullUrl);

        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);
        console.log("ContainerClient initialized for container:", containerInfo.container_name);

        const blockBlobClient = containerClient.getBlockBlobClient(`Models/${modelName}`);
        console.log("BlockBlobClient initialized for model:", modelName);

        await blockBlobClient.delete();
        console.log(`Model ${modelName} deleted successfully`);
    } catch (error) {
        console.error("Error deleting model:", error.message);
        throw error;
    }
};


export const uploadPhoto = async (containerInfo, modelName, photoFile) => {
    try {
        console.log("Starting photo upload...");
        console.log("Container info:", containerInfo);
        console.log("Model name:", modelName);
        console.log("Photo file:", photoFile);

        const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
        console.log("Full URL for BlobServiceClient:", fullUrl);

        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);
        console.log("ContainerClient initialized for container:", containerInfo.container_name);

        const photoId = uuidv4();
        console.log("Generated photo ID:", photoId);

        const blobName = `Models/${modelName}/TrainingSet/${photoId}`;
        console.log("Blob name for photo:", blobName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        console.log("BlockBlobClient initialized for blob:", blobName);

        await blockBlobClient.uploadBrowserData(photoFile);
        console.log(`Photo uploaded successfully: ${blobName}`);
    } catch (error) {
        console.error('Error uploading photo:', error);
        throw error;
    }
};

export const getTrainingSetPhotos = async (containerInfo, modelName) => {
    try {
        const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);

        const photos = [];
        for await (const blob of containerClient.listBlobsFlat({ prefix: `Models/${modelName}/TrainingSet/` })) {
            if (!blob.name.endsWith("placeholder.txt")) {
                photos.push(blob.name.split('/').pop()); // Extract the GUID from the blob name
            }
        }

        return photos;
    } catch (error) {
        console.error('Error fetching photos:', error);
        throw error;
    }
};


export const getTrainingSetPhotoContent = async (containerInfo, modelName, photoGuid) => {
    try {
        console.log("Fetching photo content...");
        console.log("Container info:", containerInfo);
        console.log("Model name:", modelName);
        console.log("Photo GUID:", photoGuid);

        const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
        console.log("Full URL for BlobServiceClient:", fullUrl);

        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);
        console.log("ContainerClient initialized for container:", containerInfo.container_name);

        const blobName = `Models/${modelName}/TrainingSet/${photoGuid}`;
        console.log("Blob name for photo:", blobName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        console.log("BlockBlobClient initialized for blob:", blobName);

        const downloadBlockBlobResponse = await blockBlobClient.download(0);
        console.log("Download response received:", downloadBlockBlobResponse);

        const downloaded = await downloadBlockBlobResponse.blobBody;
        console.log("Blob body downloaded:", downloaded);

        const url = URL.createObjectURL(downloaded);
        console.log("Object URL created:", url);

        return url;
    } catch (error) {
        console.error('Error fetching photo content:', error);
        throw error;
    }
};


export const deleteTrainingSetPhoto = async (containerInfo, modelName, photoGuid) => {
    try {
      const fullUrl = `${containerInfo.account_url}?${containerInfo.sas_token}`;
      const blobServiceClient = new BlobServiceClient(fullUrl);
      const containerClient = blobServiceClient.getContainerClient(containerInfo.container_name);
  
      const blobName = `Models/${modelName}/TrainingSet/${photoGuid}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  
      await blockBlobClient.delete();
  
      console.log(`Photo deleted successfully: ${blobName}`);
    } catch (error) {
      console.error('Error deleting photo:', error);
      throw error;
    }
  };
  