// src/components/MediaContent.jsx
import React, { useState } from 'react';
import MediaContentLeft from './MediaContentLeft';
import MediaContentRight from './MediaContentRight';

const MediaContent = () => {
  const [selectedModel, setSelectedModel] = useState(null);

  const handleModelSelect = (model) => {
    console.log('Model selected:', model);
    setSelectedModel(model);
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <MediaContentLeft onModelSelect={handleModelSelect} />
      <MediaContentRight selectedModel={selectedModel} />
    </div>
  );
};

export default MediaContent;
