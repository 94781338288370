import React from "react";
import ReactDOM from "react-dom/client";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";

import { msalConfig } from "./authConfig.js";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";

// MSAL should be instantiated outside of the component tree
// to prevent it from being re-instantiated on re-renders.

const msalInstance = new PublicClientApplication(msalConfig);

console.log("[index.js] msalInstance: ", msalInstance);
console.log("[index.js] is authenticated: ", msalInstance.getActiveAccount() !== null);
console.log("[index.js] number of accounts: ", msalInstance.getAllAccounts().length);

// check if there is an active account on page load
if (msalInstance.getActiveAccount()) {
    console.log("[index.js] Active account found on page load: ", msalInstance.getActiveAccount());
} else {
    console.log("[index.js] No active account found on page load.");
    // check if there are any accounts in cache
    if (msalInstance.getAllAccounts().length > 0) {
        console.log("[index.js] Setting the first available account as active.");
        const firstAccount = msalInstance.getAllAccounts()[0];
        msalInstance.setActiveAccount(firstAccount);
        console.log("[index.js] Active account set to: ", firstAccount);
    } else {
        console.log("[index.js] No accounts available in cache.");
    }
}

msalInstance.addEventCallback((event) => {
    console.log("[index.js] Event received: ", event);
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        console.log("[index.js] Setting active account from event payload.");
        msalInstance.setActiveAccount(event.payload.account);
    } else {
        console.log("[index.js] No account in the event payload.");
    }
});

ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <App instance = {msalInstance} />
    </BrowserRouter>
);
