// src/components/TrainingSetItem.jsx
import React from 'react';
import { FaTrash } from 'react-icons/fa';

const TrainingSetItem = ({ photo, isSelected, onClick, onDelete }) => {
  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent the parent onClick from being triggered
    onDelete(photo);
  };

  return (
    <li
      onClick={() => onClick(photo)}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        margin: '5px 0',
        backgroundColor: isSelected ? '#d3d3d3' : '#f9f9f9',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #ddd'
      }}
    >
      <span>{photo}</span>
      <FaTrash style={{ cursor: 'pointer' }} onClick={handleDeleteClick} />
    </li>
  );
};

export default TrainingSetItem;
