import { Routes, Route } from "react-router-dom";
import { MsalProvider, useMsal } from '@azure/msal-react';
import { NavigationBar } from './components/NavigationBar';

import { Home } from './pages/Home';
import { TodoList } from './pages/TodoList';
import MySQLPage from './pages/MySQLPage';
import Media from './pages/Media'; // Adjust the path as necessary

import './styles/App.css';

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <NavigationBar />
            <Routes>
                <Route path="/todolist" element={<TodoList />} />
                <Route path="/media" element={<Media />} />
                <Route path="/mysql" element={<MySQLPage />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </MsalProvider>
    );
};

export default App;
