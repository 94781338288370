// src/components/TrainingSetList.jsx
import React, { useState } from 'react';
import TrainingSetItem from './TrainingSetItem';

const TrainingSetList = ({ photos, onSelect, onDelete }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    onSelect(photo);
  };

  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {photos.map((photo, index) => (
        <TrainingSetItem
          key={index}
          photo={photo}
          isSelected={selectedPhoto === photo}
          onClick={handlePhotoClick}
          onDelete={onDelete}
        />
      ))}
    </ul>
  );
};

export default TrainingSetList;