// src/components/ModelItem.jsx
import React from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { deleteModel } from '../utils/mediaUtils';

const ModelItem = ({ model, isSelected, onClick, sasInfo }) => {
  const handleDeleteClick = async (e) => {
    e.stopPropagation(); // Prevent the parent onClick from being triggered
    const isConfirmed = window.confirm(`Are you sure you want to delete the model "${model}"?`);
    if (isConfirmed) {
      try {
        await deleteModel(sasInfo, model);
      } catch (error) {
        console.error('Error deleting model:', error);
      }
    }
  };

  return (
    <li
      onClick={() => onClick(model)}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        margin: '5px 0',
        backgroundColor: isSelected ? '#d3d3d3' : '#f9f9f9',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #ddd'
      }}
    >
      <span>{model}</span>
      <div style={{ display: 'flex', gap: '10px' }}>
        <FaEdit style={{ cursor: 'pointer' }} />
        <FaTrash style={{ cursor: 'pointer' }} onClick={handleDeleteClick} />
      </div>
    </li>
  );
};

export default ModelItem;