// src/components/MediaRightContentTrainingSetAdd.jsx
import React from 'react';

const MediaRightContentTrainingSetAdd = ({ onUpload }) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onUpload(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="file-upload"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload" style={{ cursor: 'pointer', padding: '10px', backgroundColor: '#007bff', color: '#fff', borderRadius: '5px' }}>
        Select Photo
      </label>
    </div>
  );
};

export default MediaRightContentTrainingSetAdd;
