// src/pages/Media.js
import React from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig'; // Adjust the path as necessary
import MediaTop from '../components/MediaTop';
import MediaContent from '../components/MediaContent';
import MediaBottom from '../components/MediaBottom';

const Media = () => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate 
      interactionType={InteractionType.Redirect} 
      authenticationRequest={authRequest}>
      <div style={{ backgroundColor: '#f0f0f0', padding: '20px' }}>
        <MediaTop />
        <MediaContent />
        <MediaBottom />
      </div>
    </MsalAuthenticationTemplate>
  );
};

export default Media;
