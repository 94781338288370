import { useState, useEffect } from 'react';
import React from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';

const MySQLPageMainContent = () => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiMySQL.scopes.read,
  });

  const [mySQLData, setMySQLData] = useState(null);

  useEffect(() => {
    if (!mySQLData) {
      execute("GET", protectedResources.apiMySQL.endpoint).then((response) => {
        console.log(response);
        setMySQLData(response);
      });
    }
  }, [execute, mySQLData]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!mySQLData) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ padding: '20px', backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <h2 style={{ color: '#333', marginBottom: '20px' }}>MySQL Data</h2>
      <p style={{ color: '#666', marginBottom: '20px' }}>This is the MySQL data that was fetched from the API:</p>
      <ul style={{ listStyleType: 'none', padding: '0' }}>
        {mySQLData.map((item) => (
          <li key={item.id} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
            <strong>ID:</strong> {item.id}, <strong>Name:</strong> {item.name}, <strong>Age:</strong> {item.age}
          </li>
        ))}
      </ul>
    </div>
  );
};

const MySQLPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      
      {/* Header with fixed height */}
      <header style={{
        height: '80px',
        textAlign: 'center',
        backgroundColor: 'rgba(173, 216, 230, 0.5)',
        padding: '20px'
      }}>
        <h1>MySQL Page</h1>
      </header>
      
      {/* Main Content Area - Expands to fill remaining space */}
      <main style={{
        flex: 1,
        overflowY: 'auto',
        backgroundColor: 'rgba(144, 238, 144, 0.5)',
        padding: '20px',
      }}>
        <section>
          <h2>Content Zone</h2>
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <MySQLPageMainContent />
          </MsalAuthenticationTemplate>
        </section>
      </main>
      
      {/* Footer with fixed height */}
      <footer style={{
        height: '60px',
        textAlign: 'center',
        backgroundColor: 'rgba(255, 182, 193, 0.5)',
        padding: '20px'
      }}>
        <p>Footer Zone: Additional information or links can go here.</p>
      </footer>
    </div>
  );
};

export default MySQLPage;