// src/components/MediaContentLeftNewModel.jsx
import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { newModel } from '../utils/mediaUtils';
import { useSasInfo } from '../hooks/useSasInfo';

const MediaContentLeftNewModel = ({ onNewModel }) => {
  const [newModelName, setNewModelName] = useState('');
  const { sasInfo } = useSasInfo();

  const handleNewModel = async () => {
    if (newModelName.trim() && sasInfo) {
      try {
        await newModel(sasInfo, newModelName);
        setNewModelName('');
        onNewModel(); // Refresh the list of models
      } catch (error) {
        console.error('Error creating new model:', error);
      }
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <input
        type="text"
        value={newModelName}
        onChange={(e) => setNewModelName(e.target.value)}
        placeholder="Enter model name"
        style={{ marginRight: '10px', padding: '5px', flex: '1' }}
      />
      <FaPlus
        onClick={handleNewModel}
        style={{ cursor: 'pointer', padding: '5px', fontSize: '30px' }} // Increased font size
      />
    </div>
  );
};

export default MediaContentLeftNewModel;