// src/components/MediaContentRight.jsx
import React, { useState, useEffect } from 'react';
import MediaRightContentTrainingSetAdd from './MediaRightContentTrainingSetAdd';
import MediaRightContentTrainingSetList from './MediaRightContentTrainingSetList';
import MediaRightContentTrainingSetListPreview from './MediaRightContentTrainingSetListPreview';
import { uploadPhoto } from '../utils/mediaUtils';
import { useSasInfo } from '../hooks/useSasInfo';

const MediaContentRight = ({ selectedModel }) => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const { sasInfo } = useSasInfo();

  useEffect(() => {
    console.log('Selected model:', selectedModel);
  }, [selectedModel]);

  const handleUpload = async (file) => {
    if (selectedModel && sasInfo) {
      try {
        await uploadPhoto(sasInfo, selectedModel, file);
        setPhotos([...photos, URL.createObjectURL(file)]);
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }
  };

  const handleSelect = (photo) => {
    setSelectedPhoto(photo);
  };

  return (
    <div>
      <MediaRightContentTrainingSetAdd onUpload={handleUpload} />
      <MediaRightContentTrainingSetList selectedModel={selectedModel} onSelect={handleSelect} />
      <MediaRightContentTrainingSetListPreview selectedModel={selectedModel} selectedPhoto={selectedPhoto} />
    </div>
  );
};

export default MediaContentRight;
